import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, addHours, addMonths, addYears } from 'date-fns';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import TargetedVehicleForm from "./TargetedVehicleForm";
import WhitelistVehicleForm from "./WhitelistVehicleForm";
import TestVehicleForm from "./TestVehicleForm";

const dateFormat = "dd/MM/yyyy";

const categoryOptions = [
  { value: "recall", label: "Recall" },
  { value: "targeted", label: "Targeted" },
  { value: "targetedTsl", label: "Targeted TSL" }
];

const subcategoriesOptions = [
  { value: "dangerousGoods", label: "Dangerous Goods"},
  { value: "driver", label: "Driver"},
  { value: "operator", label: "Operator"},
  { value: "loadSecurity", label: "Load Security" },
  { value: "overDimension", label: "Over Dimension" },
  { value: "permit", label: "Permit" },
  { value: "ruc", label: "RUC" },
  { value: "speed", label: "Speed" },
  { value: "vehicleDefects", label: "Vehicle Defects" },
  { value: "weight", label: "Weight" },
];

const whitelistCategoryOptions = [
  { value: "whitelist", label: "Whitelist" }
];

const whitelistSubcategoriesOptions = [
  { value: "driverChangeOver", label: "Driver Change Over"},
];

const assessmentTypeOptions = [
  { value: "COF_DUE_EXPIRY", label: "COF Due Expiry"},
  { value: "IDENTIFICATION", label: "Identification"},
  { value: "LATEST_INSPECTION", label: "Latest Inspection (COF)"},
  { value: "MEASUREMENT", label: "Measurement"},
  { value: "MVR", label: "MVR"},
  { value: "PERMIT", label: "Permit"},
  { value: "RANDOM", label: "Random"},
  { value: "RISK", label: "Risk"},
  { value: "RUC", label: "RUC"},
  { value: "STOLEN_OF_INTEREST", label: "Stolen of Interest"},
  { value: "TARGETED_VEHICLE", label: "Targeted Vehicle"},
  { value: "UNCONFIRMED_BUYER", label: "Unconfirmed Buyer"},
  { value: "VDAM", label: "VDAM"},
  { value: "VEHICLE_LICENCE", label: "Vehicle Licence"},
  { value: "VEHICLE_NOTICE", label: "Vehicle Notice"},
];

const testVehicleCategoryOptions = [
  { value: "testvehicle", label: "Test Vehicle" }
];

const testVehicleSubcategoriesOptions = [
  { value: "test", label: "Test Vehicle"},
];

function calculateExpiryDate(defaultExpiryTime) {
  const { timeUnit, value } = defaultExpiryTime;
  switch (timeUnit) {
    case 'HOURS':
      return addHours(new Date(), value);
    case 'DAYS':
      return addDays(new Date(), value);
    case 'MONTHS':
      return addMonths(new Date(), value);
    case 'YEARS':
      return addYears(new Date(), value);
    default:
      throw new Error(`Invalid time unit: ${timeUnit}`);
  }
}

function CategoryWatchedExpiry({ control, useWatch, type, setValue, targetedVehicleDefaultValues, defaultValue, disabledExpiry, errors, maxDate }) {
  const category = useWatch({
    control,
    name: "category", // without supply name will watch the entire form, or ['expiry', 'numberPlate'] to watch both
    defaultValue: type || "", // default value before the render
  });

  const defaultExpiryTime = targetedVehicleDefaultValues.find(val => {
    if (val.key === "TARGETED_TSL") {
      return category === "targetedTsl";
    } else if (val.key === "TEST_VEHICLE") {
      return category === "testvehicle" || category === "test_vehicle";
    } else {
      return val.key === category.toUpperCase();
    }
  });

  const defaultExpiry = defaultExpiryTime ? calculateExpiryDate(defaultExpiryTime) : null;
  
  if (defaultValue) {
    setValue('expiry', defaultValue);
  } else if (defaultExpiry) { 
    setValue('expiry', defaultExpiry);
  } else {
    setValue('expiry', addMonths(new Date(), 6));
  }

  return (
    <Controller
      name="expiry"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl error={!!errors.expiry} required fullWidth>
          <FormLabel >
            Expiry
          </FormLabel>
          <DatePicker
            format={"dd/MM/yyyy"}
            value={new Date(value)}
            onChange={(event) => { onChange(event); }}
            required
            disabled={disabledExpiry}
            maxDate={maxDate}
            disablePast
          />
          <FormHelperText>{errors?.expiry?.message}</FormHelperText>
        </FormControl>
      )}
    />
  )
};

function DynamicApproachSelectInput({ control, safetyCentre, safetyCentres, defaultValue }) {

  const foundSafetyCentre =  safetyCentres ? Object.values(safetyCentres).find(sc => sc.id === safetyCentre) : null;

  return (
    <Controller
      name="approachId"
      control={control}
      render={({ field }) => (
          <Select 
            {...field} 
            label="Approach" 
            labelId="approach-select-label"
            name="approachId"
            defaultValue={defaultValue || ""} 
            sx={{ 
              backgroundColor: theme => theme.palette.primary.text,
              borderRadius: 1.5,
              mb: 2,
            }}
          >
            { !foundSafetyCentre ? <MenuItem key={""} value={""}>{"Please select a safety centre"}</MenuItem> :  
              foundSafetyCentre.approaches.map(approach => (
                <MenuItem key={approach.id} value={approach.id}>{approach.name}</MenuItem>
              ))
            }
          </Select>
      )}
    />
  )
};

const NumberPlateTargetedAndRecallInput = ({ control, errors, defaultValues, disabledPlate, title }) => (
  <div style={{ marginRight: "16px", flex: 1 }}>
    <Controller
      render={({ field }) => (
        <FormControl error={!!errors.numberPlate}  fullWidth>
          <FormLabel >
            {title}
          </FormLabel>
          <TextField
            {...field}
            variant="outlined"
            error={!!errors.numberPlate}
            helperText={errors?.numberPlate?.message}
            fullWidth
            disabled={disabledPlate}
            defaultValue={defaultValues?.numberPlate ? defaultValues.numberPlate : defaultValues?.tslNumber ? defaultValues.tslNumber : ""}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </FormControl>
      )}
      name="numberPlate"
      control={control}
    />
  </div>
);

const NumberPlateWhiteListAndTestInput = ({ control, errors, defaultValues, disabledPlate, title }) => (
  <div style={{ marginRight: "16px", flex: 1 }}>
    <Controller
      render={({ field }) => (
        <FormControl error={!!errors.numberPlate}  fullWidth>
          <FormLabel >
            {title}
          </FormLabel>
          <TextField
            {...field}
            variant="outlined"
            error={!!errors.numberPlate}
            helperText={errors?.numberPlate?.message}
            fullWidth
            disabled={disabledPlate}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </FormControl>
      )}
      name="numberPlate"
      control={control}
    />
  </div>
);

const StartInput = ({ control, errors, disabledStart }) => (
  <div style={{ flex: 1 }}>
    <Controller
      name="start"
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <FormControl error={!!errors.start} required fullWidth>
          <FormLabel >
            Start
          </FormLabel>
          <DatePicker
            format={dateFormat}
            value={new Date(value)}
            onChange={onChange}
            required
            disabled={disabledStart}
          />
          <FormHelperText>{errors?.start?.message}</FormHelperText>
        </FormControl>
      )}
    />
  </div>
);

const CategoryInput = ({ control, errors, defaultValues, disabledCategory, options }) => (
  <div style={{ marginRight: "16px", flex: 1 }}>
    <Controller
      name="category"
      control={control}
      render={({ field }) => (
        <FormControl error={!!errors.category} required fullWidth>
          <FormLabel >
            Category
          </FormLabel>
          <Select 
            {...field} 
            variant="outlined" 
            fullWidth
            disabled={disabledCategory}
            defaultValue={defaultValues?.category ? defaultValues.category : ""}
          >
            {options.map(cat => (
              <MenuItem key={cat.value} value={cat.value}>{cat.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors?.category?.message}</FormHelperText>
        </FormControl>
      )}
    />
  </div>
);

const SubcategoriesInput = ({ control, errors, defaultValues, disabledSubcategories, options }) => (
  <div style={{ flex: 1 }}>
    <Controller
      name="subcategories"
      control={control}
      render={({ field }) => (
        <FormControl error={!!errors.subcategories} required fullWidth>
          <FormLabel >
            Subcategories
          </FormLabel>
          <Select 
            {...field} 
            variant="outlined" 
            multiple 
            fullWidth 
            disabled={disabledSubcategories}
            defaultValue={defaultValues?.subcategories ? defaultValues.subcategories : []}
          >
            {options.map(cat => (
              <MenuItem key={cat.value} value={cat.value}>{cat.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors?.subcategories?.message}</FormHelperText>
        </FormControl>
      )}
    />
  </div>
);

const ReasonInput = ({ control, errors, defaultValue }) => (
  <div style={{ display: "flex", flex: 1 }}>
    <Controller
      render={({ field }) => (
        <FormControl error={!!errors.reason} required fullWidth>
          <FormLabel >
            Reason
          </FormLabel>
          <TextField
            {...field}
            variant="outlined"
            error={!!errors.reason}
            helperText={errors?.reason?.message}
            required
            fullWidth
          />
        </FormControl>
      )}
      name="reason"
      control={control}
    />
  </div>
);

const RequesterNameInput = ({ control, errors, defaultValue }) => (
  <div style={{ marginRight: "16px", flex: 1 }}>
    <Controller
      render={({ field }) => (
        <FormControl error={!!errors.requesterName} required fullWidth>
          <FormLabel >
            Requester Name
          </FormLabel>
          <TextField
            {...field}
            variant="outlined"
            error={!!errors.requesterName}
            helperText={errors?.requesterName?.message}
            required
            fullWidth
          />
        </FormControl>
      )}
      name="requesterName"
      control={control}
    />
  </div>
);

const RequesterEmailInput = ({ control, errors, defaultValue }) => (
  <div style={{ flex: 1 }}>
    <Controller
      render={({ field }) => (
        <FormControl error={!!errors.requesterEmail} required fullWidth>
          <FormLabel >
            Requester Email
          </FormLabel>
          <TextField
            {...field}
            variant="outlined"
            error={!!errors.requesterEmail}
            helperText={errors?.requesterEmail?.message}
            required
            fullWidth
          />
        </FormControl>
      )}
      name="requesterEmail"
      control={control}
    />
  </div>
);

const CancelButton = ({ handleCancel }) => (
  <Button
    className="mx-8"
    type="button"
    onClick={handleCancel}
  >
    Cancel
  </Button>
);

const SubmitButton = ({ isValid }) => (
  <Button
    className="mx-8"
    variant="contained"
    color="secondary"
    type="submit"
    disabled={ /*_.isEmpty(dirtyFields) || */ !isValid}
  >
    Submit
  </Button>
);

export {
  categoryOptions,
  subcategoriesOptions,
  whitelistCategoryOptions,
  whitelistSubcategoriesOptions,
  assessmentTypeOptions,
  testVehicleCategoryOptions,
  testVehicleSubcategoriesOptions,
  calculateExpiryDate,
  TargetedVehicleForm,
  WhitelistVehicleForm,
  TestVehicleForm,
  CategoryWatchedExpiry,
  DynamicApproachSelectInput,
  NumberPlateTargetedAndRecallInput,
  NumberPlateWhiteListAndTestInput,
  StartInput,
  CategoryInput,
  SubcategoriesInput,
  ReasonInput,
  RequesterNameInput,
  RequesterEmailInput,
  CancelButton,
  SubmitButton
}