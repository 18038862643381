import { del, get, post, put, patch } from '../../http';

export const searchSafetyCentres = () => {
  return get('/api/app/v1/safety-centres');
};

export const retrieveSafetyCentre = (id) => {
  return get(`/api/app/v1/safety-centres/${id}`);
};

export const updateSafetyCentre = (safetyCentre) => {
  return put(`/api/app/v1/safety-centres/${safetyCentre.safetyCentreId}`, safetyCentre.updateRequest);
};

export const retrieveSafetyCentreHistory = (id) => {
  return get(`/api/app/v1/safety-centres/${id}/history`);
};

export const getSafetyCentreStatusReport = (scid) => {
  return get(`/api/app/v1/safety-centres/${scid}/status-report`);
};

export const setApproachStatus = (scid, aid, status, screeningRule) => {
  return put(`/api/app/v1/safety-centres/${scid}/approaches/${aid}/status`, {
    status,
    screeningRule,
  });
};

export const searchPassages = (scid, cursor) => {
  return get(`/api/app/v1/passages/${scid}`, {
    cursor,
  });
};

export const setClientConnection = () => {
  return put(`/api/app/v1/client-connection`, {});
};

export const searchMeasuredCombinations = (scid, approachId, plate, from, to, assessmentType, cursor) => {
  return get(`/api/app/v1/measured-combinations`, {
    safetyCentreId: scid,
    approachId: approachId,
    plate: plate,
    from: from,
    to: to,
    assessmentType: assessmentType,
    cursor: cursor,
  });
};

export const retrieveMeasuredCombination = (id) => {
  return get(`/api/app/v1/measured-combinations/${id}`);
};

export const searchTargetedVehicles = (cursor) => {
  return get('/api/app/v1/targeted-vehicles', {
    cursor,
  });
};

export const saveTargetedVehicle = (tv) => {
  return post(`/api/app/v1/targeted-vehicles`, tv);
};

export const deleteTargetedVehicle = (id) => {
  return del(`/api/app/v1/targeted-vehicles/${id}`);
};

export const updateTargetedVehicles = (tvs) => {
  return patch(`/api/app/v1/targeted-vehicles`, tvs);
};

export const getGlobalParameters = () => {
  return get(`/api/app/v1/targeted-vehicles/defaults`);
};

export const updateGlobalParameter = (values) => {
  return put(`/api/app/v1/targeted-vehicles/defaults`, values);
};
