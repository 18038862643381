import React, { useState } from 'react';
import styled from 'styled-components';

import {memoize} from '@helpers';
import {v1Colors} from "@helpers"
import useResizeObserver from '../hooks/userResizeObserver';


const SIMPLE_DRAWBAR = 300;
const SEMI_LENGTH = 100;
const WHEEL_RADIUS = 30;

// convert axle model to pixel distance
const calculateWidth = memoize((model) => {
  // sum of spacings + wheels front and back
  // plust leading front and rear wheel radius
  return (
    2 * WHEEL_RADIUS +
    model.axles.reduce((acc, axle) => {
      return acc + axle.spacing * 100;
    }, 0)
  );
});

// convert axle model to pixel based offsets for axle centres
// includes leading wheel radius
const calculateCentres = memoize((model) => {
  return model.axles.reduce((acc, axle) => {
    const first = acc.length === 0;
    const spacing = axle.spacing * 100;
    acc.push(((!first && acc[acc.length - 1]) || WHEEL_RADIUS) + spacing);
    return acc;
  }, []);
});

// convert axle model to pixel based offsets for axle spans
const calculateAxleSpans = (model) => {
  const centres = calculateCentres(model);
  return model.axles.slice(1).map((a, i) => {
    return {
      from: centres[i],
      to: centres[i + 1],
      span: (Math.round(a.spacing * 100) / 100).toFixed(2),
    };
  });
};

// convert axle model to pixel based offsets for set spans
// sets display as start and finish of wheel rather than centres
const calculateSetSpans = (model) => {
  const centres = calculateCentres(model);
  return model.axleSets.map((s) => {
    return {
      from: centres[s.firstAxleIndex] - WHEEL_RADIUS,
      to: centres[s.lastAxleIndex] + WHEEL_RADIUS,
      span: (Math.round(s.span * 100) / 100).toFixed(2),
      weight: s.weight,
      count: s.lastAxleIndex - s.firstAxleIndex + 1,
    };
  });
};

// convert axle model to pixel based offsets for group spans
// groups display as start and finish of wheel rather than centres
const calculateGroupSpans = (model) => {
  const centres = calculateCentres(model);
  return model.axleGroups.map((g) => {
    return {
      from: centres[g.firstAxleIndex] - WHEEL_RADIUS,
      to: centres[g.lastAxleIndex] + WHEEL_RADIUS,
      span: (Math.round(g.span * 100) / 100).toFixed(2),
      weight: g.weight,
    };
  });
};

export const CombinationStructure = ({ measuredCombination, print = false, renderSeperator }) => {
  const [showSpans, setShowSpans] = useState(false);

  // observe the size of the container to scale accordingly
  const [containerRef, containerEntry] = useResizeObserver();
  let width =
    containerEntry && containerEntry.contentRect
      ? containerEntry.contentRect.width
      : 0;

  // for print
  width = print ? 730 : width;

  const category = measuredCombination.combinationCategory;
  const model = measuredCombination.axleInformation;

  // apply any failures
  if (measuredCombination.assessmentInformation.assessments) {
    measuredCombination.assessmentInformation.assessments
      .filter((ass) => {
        return ass.type === 'VDAM' && ass.severity === 'FAILURE';
      })
      .forEach((ass) => {
        const category = ass.details.category;
        const index = ass.details.index;

        if (category === 'axle') {
          model.axles[index].failed = true;
        } else if (category === 'set') {
          model.axleSets[index].failed = true;
        } else if (category === 'group') {
          model.axleGroups[index].failed = true;
        }
      });
  }

  const rearBumper = measuredCombination.measurements.rearBumperSpace * 100;
  // add in rear bumper for rigid simple
  const combinationWidth =
    calculateWidth(model) +
    (category === 'RIGID_SIMPLE_TRAILER' ? rearBumper : 0);
  const standardWidth = 20 * 100; // 20 metres
  const centres = calculateCentres(model);
  const axleSpans = calculateAxleSpans(model);
  const setSpans = calculateSetSpans(model);
  const groupSpans = calculateGroupSpans(model);

  // scale to standard width or combination length if longer
  const scale =
    width === 0 ? 0 : width / Math.max(combinationWidth, standardWidth);

  // offset to centre the thing
  const offset = -(width / 2) + (combinationWidth * scale) / 2;

  const height =
    (150 + // combination;
      (print || showSpans
        ? 2 * (WHEEL_RADIUS + 12) + groupSpans.length * (WHEEL_RADIUS + 12)
        : 0)) *
    scale;

  let vehicles = null;
  switch (category) {
    case 'RIGID':
      vehicles = <Rigid model={model} />;
      break;
    case 'RIGID_SIMPLE_TRAILER':
      vehicles = <RigidSimple model={model} rear={rearBumper} />;
      break;
    case 'RIGID_FULL_TRAILER':
      vehicles = <RigidFull model={model} />;
      break;
    case 'RIGID_SEMI_TRAILER':
      vehicles = <RigidSemi model={model} />;
      break;
    case 'A_TRAIN':
      vehicles = <ATrain model={model} />;
      break;
    case 'B_TRAIN':
      vehicles = <BTrain model={model} />;
      break;
    default:
      break;
  }

  return (
    <>
      <CombinationContainer
        height={height}
        ref={containerRef}
        onClick={() => setShowSpans(print ? print : !showSpans)}
        style={{ borderTop: renderSeperator && `2px solid ${v1Colors.color_border}` }}
      >
        {width > 0 && (
          <Combination
            className="combination-structure"
            offset={offset}
            scale={scale}
          >
            <Direction>FRONT</Direction>
            {vehicles}
            <Axles>
              {axleSpans.map((s, i) => {
                return (
                  <Span
                    key={`s${i}`}
                    from={s.from}
                    to={s.to}
                    label={s.span}
                    type="axle"
                  >
                    <SpanLabel vertical={s.span < 1.0}>{s.span}</SpanLabel>
                  </Span>
                );
              })}
              {centres.map((c, i) => {
                return (
                  <Axle
                    key={c}
                    centre={c}
                    weight={model.axles[i].weight}
                    type={model.axles[i].tyreType}
                    failed={model.axles[i].failed === true}
                  />
                );
              })}
            </Axles>
            {((print) || (showSpans)) && (
              <>
                <Sets>
                  {setSpans.map((s, i) => {
                    let label;
                    if (s.count <= 2) {
                      // no span needed and doesn't fit anyway
                      label = s.weight;
                    } else {
                      label = `${s.weight} / ${s.span}`;
                    }
                    return (
                      <Span
                        key={`s${i}`}
                        from={s.from}
                        to={s.to}
                        type="set"
                        failed={model.axleSets[i].failed === true}
                      >
                        <SpanLabel>{label}</SpanLabel>
                      </Span>
                    );
                  })}
                </Sets>
                <Groups>
                  {groupSpans.map((g, i) => {
                    return (
                      <Group key={`g${i}`}>
                        <Span
                          from={g.from}
                          to={g.to}
                          type="group"
                          failed={model.axleGroups[i].failed === true}
                        >
                          <SpanLabel>{`${g.weight} / ${g.span}`}</SpanLabel>
                        </Span>
                      </Group>
                    );
                  })}
                </Groups>
              </>
            )}
          </Combination>
        )}
      </CombinationContainer>
    </>
  );
};

const CombinationContainer = styled.div`
  width: 100%;
  cursor: pointer;
  /* must have fixed height to adjust for scale */
  height: ${(props) => props.height}px;
  padding-top: 1.5em;
`;

const Combination = styled.div`
  position: relative;
  transform-origin: top right;
  transform: scale(${(props) => props.scale || 1});
  padding-top: 30px;
  left: ${(props) => props.offset}px;
  font-size: 173%;
`;

const Direction = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: #999;
`;

const Vehicles = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
`;

const Vehicle = styled.div`
  position: absolute;
  right: ${(props) => props.from || 0}px;
  top: 0;
  width: ${(props) => props.length}px;
  height: 10px;
  background-color: black;
`;

const Space = styled.div`
  position: absolute;
  right: ${(props) => props.from || 0}px;
  top: 0;
  width: ${(props) => props.length}px;
  height: 10px;
  background-color: white;
`;

const SemiOverlap = styled.div`
  width: ${(props) => props.offset + SEMI_LENGTH}px;
  height: 10px;
  background-color: black;
  position: absolute;
  top: -20px;
  right: ${(props) => props.from - props.offset}px;

  :after {
    content: '';
    display: block;
    height: 30px;
    width: 10px;
    background-color: black;
    position: absolute;
    left: 0px;
    top: 0px;
  }
`;

const Axles = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

const Axle = ({ centre, weight, type, failed }) => {
  let abbr = '';
  switch (type) {
    case 'SINGLE_STANDARD':
      abbr = 'SS';
      break;
    case 'SINGLE_LARGE':
      abbr = 'SL';
      break;
    case 'SINGLE_MEGA':
      abbr = 'SM';
      break;
    case 'TWIN':
      abbr = 'T';
      break;
    case 'OSCILLATING_4':
      abbr = '4';
      break;
    case 'OSCILLATING_8':
      abbr = '8';
      break;
    case 'OSCILLATING_16':
      abbr = '16';
      break;
    default:
      break;
  }

  return (
    <Wheel centre={centre} failed={failed}>
      <Hub />
      <TyreType>{abbr}</TyreType>
      <AxleWeight failed={failed}>{weight}</AxleWeight>
    </Wheel>
  );
};

const Wheel = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) => (props.failed ? v1Colors.color_error : 'black')};
  position: absolute;
  top: 0;
  right: ${(props) => props.centre - 30 || 0}px;
  text-align: center;
  line-height: 60px;
  z-index: 2;
`;

const Hub = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 9px;
  left: 9px;
`;

const TyreType = styled.div`
  position: relative;
`;

const AxleWeight = styled.div`
  position: absolute;
  height: 20px;
  width: ${2 * WHEEL_RADIUS}px;
  line-height: 20px;
  top: 70px;
  text-align: center;
  color: ${(props) => (props.failed ? v1Colors.color_error : 'inherit')};
`;

const Span = styled.div`
  width: ${(props) =>
    props.to - props.from === 0 ? 2 * WHEEL_RADIUS : props.to - props.from}px;
  position: absolute;
  top: 0;
  right: ${(props) => (props.to - props.from === 0 ? 0 : props.from)}px;
  text-align: center;

  &:before {
    /* default is axle type */
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: #ddd;
    right: 0;
    top: ${(props) => WHEEL_RADIUS - 1}px;

    ${(props) =>
      props.type === 'set'
        ? `
        height: 32px;
        background-color: #444;
        top: ${WHEEL_RADIUS - 12}px;
      `
        : null};

    ${(props) =>
      props.type === 'group'
        ? `
        height: 32px;        
        top: 3px;
      `
        : null};

    /* override for failed */
    ${(props) =>
      props.failed
        ? `
      background-color: ${v1Colors.color_error};      
    `
        : null}
  }

  > div {
    display: inline-block;
    white-space: nowrap;
    line-height: ${2 * WHEEL_RADIUS + 8}px;
    background-color: white;
    position: relative;
    z-index: 1;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    ${(props) =>
      props.type === 'set'
        ? `
        background-color: transparent;
        color: white;
        `
        : null};

    ${(props) =>
      props.type === 'group'
        ? `
        background-color: transparent;
        line-height: ${WHEEL_RADIUS + 8}px;
      `
        : null}

    /* override for failed */
    ${(props) =>
      props.failed
        ? `
      color: white;      
    `
        : null}
  }
`;

const SpanLabel = styled.div`
  ${(props) => (!!props.vertical ? 'transform: rotate(-90deg);' : null)}
`;

const Sets = styled.div`
  width: 100%;
  height: ${(props) => 2 * WHEEL_RADIUS}px;
  position: relative;
`;

const Groups = styled.div``;

const Group = styled.div`
  width: 100%;
  height: ${(props) => WHEEL_RADIUS + 12}px;
  position: relative;
`;

const Rigid = ({ model }) => {
  return (
    <Vehicles>
      <Vehicle from={0} length={calculateWidth(model)} />
    </Vehicles>
  );
};

const RigidSimple = ({ model, rear }) => {
  // A simple trailer has one axle set.
  // - rigid is all axle sets except last plus a bit (1m)
  // - space for drawbar
  // - rest is trailer
  // - show rear bumper
  const l = calculateWidth(model) + rear;
  const cs = calculateCentres(model);
  const lastRigidAxleIndex =
    model.axleSets[model.axleSets.length - 1].firstAxleIndex - 1;

  const v1l = cs[lastRigidAxleIndex] + WHEEL_RADIUS;

  const v2l = l - SIMPLE_DRAWBAR - v1l;

  return (
    <Vehicles>
      <Vehicle from={0} length={v1l} />
      <Space from={v1l} length={SIMPLE_DRAWBAR} />
      <Vehicle from={v1l + SIMPLE_DRAWBAR} length={v2l} />
    </Vehicles>
  );
};

const RigidFull = ({ model }) => {
  // A full trailer has two axle sets.
  // - rigid is all axle sets except last two plus a bit (1m)
  // - space for drawbar
  // - rest is trailer
  const l = calculateWidth(model);
  const cs = calculateCentres(model);
  const lastRigidAxleIndex =
    model.axleSets[model.axleSets.length - 2].firstAxleIndex - 1;

  const v1l = cs[lastRigidAxleIndex] + WHEEL_RADIUS;

  const firstTrailerIndex = lastRigidAxleIndex + 1;
  const trailerStart = cs[firstTrailerIndex] - WHEEL_RADIUS;
  const drawbar = trailerStart - v1l;

  const v2l = l - drawbar - v1l;

  return (
    <Vehicles>
      <Vehicle from={0} length={v1l} />
      <Space from={v1l} length={drawbar} />
      <Vehicle from={v1l + drawbar} length={v2l} />
    </Vehicles>
  );
};

const RigidSemi = ({ model }) => {
  // A semi trailer includes all the axle sets that are not on the rigid vehicle.
  // - rigid is first two sets plus a bit (1m)
  // - overlap from centre of last rigid set
  // - rest is trailer
  const l = calculateWidth(model);
  const cs = calculateCentres(model);
  const lastRigidSet = model.axleSets[1];
  const semiPoint =
    // centre of last two axles in set
    cs[lastRigidSet.lastAxleIndex - 1] +
    (cs[lastRigidSet.lastAxleIndex] - cs[lastRigidSet.lastAxleIndex - 1]) / 2;

  const v1l = cs[lastRigidSet.lastAxleIndex] + WHEEL_RADIUS;
  const offset = v1l - semiPoint;

  const v2l = l - v1l - SEMI_LENGTH;

  return (
    <Vehicles>
      <Vehicle from={0} length={v1l} />
      <SemiOverlap from={v1l} offset={offset} />
      <Vehicle from={v1l + SEMI_LENGTH} length={v2l} />
    </Vehicles>
  );
};

const ATrain = ({ model }) => {
  // An a-train is rigid-semi-full
  // from the back
  // - two sets for full trailer
  // - one set for semi
  // - rest are the rigid
  // - overlap from centre of last rigid set

  const l = calculateWidth(model);
  const cs = calculateCentres(model);

  const lastRigidSet = model.axleSets[model.axleSets.length - 4];
  const v1l =
    WHEEL_RADIUS + cs[lastRigidSet.lastAxleIndex] - cs[0] + WHEEL_RADIUS;

  const semiPoint =
    cs[lastRigidSet.lastAxleIndex - 1] +
    (cs[lastRigidSet.lastAxleIndex] - cs[lastRigidSet.lastAxleIndex - 1]) / 2;

  const offset = v1l - semiPoint;
  const v2l =
    cs[model.axleSets[model.axleSets.length - 3].lastAxleIndex] -
    semiPoint -
    offset -
    SEMI_LENGTH +
    WHEEL_RADIUS;

  const v3l =
    2 * WHEEL_RADIUS +
    cs[model.axleSets[model.axleSets.length - 1].lastAxleIndex] -
    cs[model.axleSets[model.axleSets.length - 2].firstAxleIndex];

  const drawbar = l - (v1l + v2l + SEMI_LENGTH + v3l);

  return (
    <Vehicles>
      <Vehicle from={0} length={v1l} />
      <SemiOverlap from={v1l} offset={offset} />
      <Vehicle from={v1l + SEMI_LENGTH} length={v2l} />
      <Space from={v1l + SEMI_LENGTH + v2l} length={drawbar} />
      <Vehicle from={v1l + SEMI_LENGTH + v2l + drawbar} length={v3l} />
    </Vehicles>
  );
};

const BTrain = ({ model }) => {
  // A b-train is rigid-semi-semi
  // from the back
  // - two sets for full trailer
  // - one set for semi
  // - rest are the rigid
  // - overlap from centre of last rigid set

  const cs = calculateCentres(model);

  const lastRigidSet = model.axleSets[model.axleSets.length - 3];
  const v1l =
    WHEEL_RADIUS + cs[lastRigidSet.lastAxleIndex] - cs[0] + WHEEL_RADIUS;

  const firstSemiPoint =
    cs[lastRigidSet.lastAxleIndex - 1] +
    (cs[lastRigidSet.lastAxleIndex] - cs[lastRigidSet.lastAxleIndex - 1]) / 2;

  const offset1 = v1l - firstSemiPoint;

  const firstSemiSet = model.axleSets[model.axleSets.length - 2];
  const v2l =
    cs[firstSemiSet.lastAxleIndex] -
    firstSemiPoint -
    offset1 -
    SEMI_LENGTH +
    WHEEL_RADIUS;

  const secondSemiPoint =
    cs[firstSemiSet.lastAxleIndex - 1] +
    (cs[firstSemiSet.lastAxleIndex] - cs[firstSemiSet.lastAxleIndex - 1]) / 2;

  const offset2 =
    cs[firstSemiSet.lastAxleIndex] + WHEEL_RADIUS - secondSemiPoint;

  const secondSemiSet = model.axleSets[model.axleSets.length - 1];
  const v3l =
    cs[secondSemiSet.lastAxleIndex] -
    secondSemiPoint -
    offset2 -
    SEMI_LENGTH +
    WHEEL_RADIUS;

  return (
    <Vehicles>
      <Vehicle from={0} length={v1l} />
      <SemiOverlap from={v1l} offset={offset1} />
      <Vehicle from={v1l + SEMI_LENGTH} length={v2l} />
      <SemiOverlap from={v1l + SEMI_LENGTH + v2l} offset={offset2} />
      <Vehicle from={v1l + SEMI_LENGTH + v2l + SEMI_LENGTH} length={v3l} />
    </Vehicles>
  );
};